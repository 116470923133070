*,
html {
  font-family: "Poppins", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
}
#root {
  min-height: -webkit-fill-available;
}
body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #6c2f6c;
  background-image: linear-gradient(0deg, #6c2f6c 0%, #1e0e1e 70%);
  background-repeat: no-repeat;
}

canvas {
  height: 100%;
  width: 100%;
}
.full {
  min-height: -webkit-fill-available;
  height: 100vh;
  width: 100%;
}
